import { useState, useEffect } from "react"

import { LadderAPI } from "../../lib/ladder.js"
import styles from "./WorkerLandingPage.module.css"
import JobCard from "../jobs/JobCard";

export default function FeaturedJobs({data}) {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    if (!data) fetchJobs();
    else setJobs(data)
  }, [])

  const fetchJobs = async () => {
    const res = await LadderAPI.jobsAndFilters();
    if(res.success) {
      setJobs(res.jobs)
    }
  }

  return(
    <div className={styles['featured-jobs']}>
      {jobs.map(job => <JobCard key={job.slug} winners={true} job={job}/>)}
    </div>
  )
}
