import Link from 'next/link'

import { FaMapMarkerAlt } from 'react-icons/fa'
import { JobAPI } from "../../lib/ladder"
import { useEffect } from "react";

import styles from './JobCard.module.css'

export default function JobCard({ winners, job }) {

  useEffect(() => {
    if (winners) {
      JobAPI.handleImpression(job.auction_id, job.id)
    }
  }, [])

  const setBorder = () => {
    if (winners) {
      return { border: '2px solid #FF7341' }
    }
  }

  const handleClick = async () => {
      await JobAPI.handleClick(job.auction_id, job.id)
  }

  return(
    <div className={styles['job-card']} style={setBorder()}>
      <div className="row">
        <div className="col-xl-9 col-lg-10 col-md-10">
          <Link href={'/e/' + job.slug}>
            <a className="clean-link" onClick={() => {handleClick()}}><p className={styles['job-name']}>{job.name}</p></a>
          </Link>
          <span className={styles["job-mobile-wage"]}>${job.min_wage} - ${job.max_wage}/hour</span>
          <p className={styles['job-employer']}><Link href={"/e/" + job.employer_slug}><a className="clean-link">{job.employer}</a></Link> <span className={styles['mobile-job-location']}><FaMapMarkerAlt style={{fontSize: "0.9rem"}}/> {job.location}</span></p>
          <div className={styles['job-data']}>
            <span className={styles['job-location']}><FaMapMarkerAlt style={{fontSize: "0.9rem"}}/> {job.location}</span>
            <span className={styles["location-break"]}>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
            <span className={styles['job-title']}>{job.title ? job.title.name : ""}</span>
            <span className={styles["title-break"]}>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
            <span className={styles['job-work-type']}>{job.work_type.name}</span>
            &nbsp;&nbsp;•&nbsp;&nbsp;
            <span className={styles['job-industry']}>{job.industry.name}</span>
            &nbsp;&nbsp;•&nbsp;&nbsp;
            <span className={styles['job-years-of-experience']}>{job.min_years_of_experience} {job.min_years_of_experience === 1 ? "year" : "years"}</span>
            <span className={styles["job-mini-mobile-wage"]}>${job.min_wage} - ${job.max_wage}/hour</span>
          </div>
        </div>
        <div className="col-xl-3 col-lg-2 col-md-2">
          <div className={"float-right " + styles["float-back-left"]}>
            <Link href={'/e/' + job.slug}>
              <a className={"btn btn-tertiary " + styles['apply-btn']} onClick={() => {handleClick()}}>Apply Now</a>
            </Link>
            <span className={styles["job-desktop-wage"]}>${job.min_wage} - ${job.max_wage}/hour</span>
          </div>
        </div>
      </div>
      

    </div>
  )
}
